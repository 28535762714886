import React from "react";

const Panels = ({ item }) => {
  if (item === "image") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Panels Settings</p>
        <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Panel Image Settings</h1>
        <div className="pt-5">
          <p className="text-sm">Allows uploading images and pattern for category boards.</p>
          <p className="font-medium mt-5">Tutorial:</p>
          <video className="rounded-xl shadow border mt-3 w-auto mx-auto" controls>
            <source src="./assets/images/panels.mp4"></source>
          </video>
        </div>
      </div>
    );
  }
  else if (item === "color") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Panels Settings</p>
        <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Panel Head Color</h1>
        <div className="pt-5">
          <p className="text-sm">You can change the color of the panel head and add your own color in both modes (light/dark) and you can also add a gradient color.</p>
          <div className="flex items-start gap-4">
            <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/panel-color.png" alt="Category panel color settings" />
            <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/color-picker.png" alt="Color picker" />
          </div>
          
          <p className="font-medium mt-8">Example (without color):</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/panel-colorless.png" alt="panel head without color" />
          
          <p className="font-medium mt-8">Example (with custom color):</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/panel-custom.png" alt="panel head with custom color" />
        
          <p className="font-medium mt-8">Example (with gradient color):</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/panel-gradient.png" alt="panel head with custom color" />
          
        </div>
      </div>
    );
  }
  else if (item === "overlay") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Panels Settings</p>
        <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Panel Head Overlay</h1>
        <div className="pt-5">
          <p className="text-sm">Add a wonderful effect to the panel image.</p>
          <div className="flex items-start gap-4">
            <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/panel-overlay.png" alt="Category panel color overlay" />
          </div>

          <p className="font-semibold text-sm mt-8">Make panel head color as overlay?</p>
          <p className="text-sm mt-1"><span className="font-semibold">Enable:</span> <span className="text-gray-600">Make the panel color over the image.</span></p>
          <p className="text-sm mt-1"><span className="font-semibold">Disable:</span> <span className="text-gray-600">Make the panel image over the color.</span></p>

          <p className="font-medium mt-8">Example (without overlay):</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/panel-overlay-1.png" alt="panel head without color" />
          
          <p className="font-medium mt-8">Example (with overlay):</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/panel-overlay-2.png" alt="panel head with custom color" />
        </div>
      </div>
    );
  }
  else if (item === "collapsible") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Panels Settings</p>
        <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Collapsible Panel</h1>
        <div className="pt-5">
          <p className="text-sm">Allows collapsing/expanding category panels.</p>
          <p className="font-medium mt-5">Example:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/category-collapse.png" alt="Collapsible Category Panel" />
        </div>
      </div>
    );
  }
  else if (item === "last-visit") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Panels Settings</p>
        <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Last Visit & Current Time</h1>
        <div className="pt-5">
          <p className="text-sm">These options will show/hide the last visit and the current time on the index page.</p>
          <p className="font-medium mt-5">Example:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/last-visit.png" alt="hide last visit and current time" />
        </div>
      </div>
    );
  }
  else if (item === "sidebars") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Panels Settings</p>
        <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Sidebars</h1>
        <div className="pt-5">
          <p className="text-sm">Enable/Disable sidebars, This option allows you to add images and ads to both sides of the content.</p>
          <p className="text-sm">You can add widgets and set the sidebar width.</p>

          <p className="font-medium mt-5">Options:</p>
          <p className="text-gray-600 text-sm mt-1">You can add, edit, delete or arrange items.</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/sidebar-widgets.png" alt="Sidebar widgets" />
          <br />

          <p className="font-medium mt-5">Example with widgets <span className="text-blue-600 mx-0.5">(Left sidebar)</span>:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/left-widgets.png" alt="Left sidebar widget" />
          <br />

          <p>For the wall clock you can change the look easily go to this path <span className="text-blue-600 mx-0.5">/styles/zeina/theme/images/widgets/clock/</span> and replace the images.</p>
          <p>You can also show or hide the second hand.</p>
          
          <p className="font-medium mt-5">Example with widgets <span className="text-blue-600 mx-0.5">(Right sidebar)</span>:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/right-widgets.png" alt="Left sidebar widget" />
          <br />
          <p className="font-medium mt-5">Example Custom <span className="text-blue-600 mx-0.5">(Left sidebar)</span>:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/left-sidebar.png" alt="Left Sidebar" />
          <br />
          <p className="font-medium mt-2">Example Custom <span className="text-blue-600 mx-0.5">(Right sidebar)</span>:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/right-sidebar.png" alt="Right Sidebar" />
          <br />
          <p className="font-medium mt-2">Example Custom <span className="text-blue-600 mx-0.5">(Both)</span>:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/both-sidebar.png" alt="Sidebar both dir" />
        </div>
      </div>
    );
  }
  else if (item === "profile") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Panels Settings</p>
        <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Profile View</h1>
        <div className="pt-5">
          <p className="text-sm">This option allows you to change the way profile data is displayed within the topic view page.</p>
          <p className="font-medium mt-5">Example <span className="text-blue-600 mx-0.5">(Left)</span>:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/left-profile.png" alt="Left profile view" />
          <br />
          <p className="font-medium mt-2">Example <span className="text-blue-600 mx-0.5">(Right)</span>:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/right-profile.png" alt="Right profile view" />
          <br />
          <p className="font-medium mt-2">Example <span className="text-blue-600 mx-0.5">(Simple)</span>:</p>
          <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/simple-profile.png" alt="Simple profile view" />
        </div>
      </div>
    );
  }
  else if (item === "code-style") {
    return (
      <div>
        <p className="mb-3 text-sm font-semibold text-blue-600">Panels Settings</p>
        <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Code Style</h1>
        <div className="pt-5">
          <p className="text-sm">Choose the code style that suits you from 50 different styles.</p>
          
          <p className="font-medium mt-5">Code Style:</p>
          <video className="rounded-xl shadow border mt-3 w-auto mx-auto" controls>
            <source src="./assets/images/code-style.mp4"></source>
          </video>
        </div>
      </div>
    );
  }
}

export default Panels;