import React from "react";

const Extenstion = () => {
  return (
    <div>
      <p className="mb-3 text-sm font-semibold text-blue-600">General Settings</p>
      <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Install Compatible Extension</h1>
      <div className="pt-5">
        <p className="text-sm">We are working hard to make the extensions that our customers need compatible with the theme, so we created a folder in which we collected the extensions that we finished making compatible.</p>
        <p className="text-sm mt-4"><span className="text-blue-600 underline"><a target="_blank" href="https://mega.nz/folder/AKlSFIyS#cjxjQqLCqXJmusPgv5IoqQ">Download compatible extensions</a></span></p>
        
        <img className="rounded-xl shadow border mt-3 w-auto" src="./assets/images/mega.png" alt="Forum Icons example" />
        <p className="text-sm mt-5">Download the extension you need and unzip the file</p>
        <p className="text-sm mt-2">Then upload the folder in the ext folder.</p>

        <img className="rounded-xl shadow border mt-4 w-auto" src="./assets/images/install-zeina-2.png" alt="Forum Icons example" />

        <p className="text-sm mt-5 text-red-600">* Note: You must install the extension first. You will find the extensions database here <span className="text-blue-600 underline"><a target="_blank" href="https://www.phpbb.com/customise/db/extensions-36">Customisation Database</a></span></p>
        <p className="text-sm mt-2">This folder will be updated continuously. If you do not find the extension you want, please contact us through the ticket system in the user panel or via email <span className="text-blue-600"><a href="mailto:support@leenoz.com">support@leenoz.com</a></span>.</p>
      </div>
    </div>
  )
}

export default Extenstion;
