import React, { useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Menu from "../components/Menu";

const DefaultLayout = () => {

  const [menuState, setMenuState] = useState(false);

  // create a function that the child component can call
  const updateState = (state) => {
    if (state === false) {
      setMenuState(state);
    }
    else {
      setMenuState(menuState => {
        return !menuState;
      });
    }
  }

  const location = useLocation();

  useLayoutEffect(() => {
    let title = 'Zeina - ' + document.querySelector('.page-title').textContent;
    document.title = title;

    updateState(false);
  }, [location]);

  return (
    <div className="flex flex-col h-full">
      <Header updateState={updateState} />
      <div className="max-w-[85rem] flex lg:gap-6 mx-auto mt-8 pt-10 w-full">
        <div className="h-full"><Menu menuState={menuState} /></div>
        <div className="grow mt-7 main lg:ps-[310px] lg:px-4 px-8">
          <Outlet />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
