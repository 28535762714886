import React from "react";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <p className="mb-3 text-sm font-semibold text-blue-600">Getting Started</p>
      <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Introduction</h1>
      <div className="pt-5 space-y-4 leading-relaxed">
        <p className="text-gray-600 text-sm mt-1">Zeina is a phpBB theme with a clean and modern design that is fully responsive to all types of devices and easy to navigate and use. It supports RTL languages, comes with a control panel so it can be easily customized and also includes a variety of color schemes, Zeina designed using HTML5 including WAI-ARIA roles and small CSS file gives the website more performance and browsing speed.</p>
        <p className="text-gray-600 text-sm mt-1">We care a lot about performance and SEO, so we minimized the CSS file and loaded libraries only when needed, and we also minimized DOM elements as much as possible.</p>
         
        <div>
          <p className="font-medium mt-6">Libraries:</p>
          <p className="text-gray-600 text-sm mt-1">We rely mainly in our template on <a href="https://tailwindcss.com/" target="_blank" className="underline text-blue-600 font-medium">Tailwind CSS</a> library.</p>
          <p className="text-gray-600 text-sm mt-1">Unlike usual, we have replaced all images into SVG icons and we have used the <a href="https://iconify.design" target="_blank" className="underline text-blue-600 font-medium">iconify.design</a> library as it provides many free icons to use.</p>
          <p className="text-gray-600 text-sm mt-1">The codes are colored and prettied it in the posts using the <a href="https://highlightjs.org/" target="_blank" className="underline text-blue-600 font-medium">highlightjs</a> library.</p>
        </div>
        <div>
          <p className="font-medium mt-6">Let's start:</p>
          <p className="text-gray-600 text-sm mt-1">First, if you haven't installed phpBB yet, you can start with the <NavLink className="underline text-blue-600 font-medium" to="zeina/install-phpBB">phpBB installation</NavLink> instructions</p>
          <p className="text-gray-600 text-sm mt-1">Then you can start <NavLink className="underline text-blue-600 font-medium" to="zeina/install-zeina-theme">installing Zeina Theme</NavLink></p>
        </div>
        <div>
          <p className="font-medium mt-6">Support and suggestions:</p>
          <p className="text-gray-600 text-sm mt-1">We are always here for you. You can use the ticket system located in your control panel if you have already purchased the product, or you can send an email to <a className="underline text-blue-600 font-medium" href="mailto:support@leenoz.com">support@leenoz.com</a></p>
        </div>
      </div>
    </div>
  );
};

export default Home;
