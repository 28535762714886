import { Route, Routes } from "react-router-dom";
import DefaultLayout from "../layouts/DefaultLayout";
import Home from "../pages/Home";
import Script from "../pages/Script";
import Theme from "../pages/Theme";
import General from "../pages/General";
import Custom from "../pages/Custom";
import Panels from "../pages/Panels";
import Navbar from "../pages/Navbar";
import Footers from "../pages/Footers";
import Headers from "../pages/Headers";
import Extenstion from "../pages/Extenstion";

const MainRouter = () => {

  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route index element={<Home />} />
        <Route path="install-phpBB" element={<Script />} />
        <Route path="install-zeina-theme" element={<Theme />} />
        <Route path="install-extenstions" element={<Extenstion />} />
        
        <Route path="logo" element={<General item="logo" />} />
        <Route path="favicon" element={<General item="favicon" />} />
        <Route path="body-image" element={<General item="body-image" />} />
        <Route path="page-layout" element={<General item="layout" />} />
        <Route path="theme-mode" element={<General item="theme-mode" />} />
        <Route path="theme-color" element={<General item="theme-color" />} />
        <Route path="font-style" element={<General item="font" />} />
        <Route path="forum-icons" element={<General item="forum-icons" />} />
        <Route path="colorful-scrollbar" element={<General item="scrollbar" />} />
        <Route path="scroll-to-top-button" element={<General item="scroll" />} />
        <Route path="animations-and-effects" element={<General item="effects" />} />

        <Route path="navbar-items" element={<Navbar item="items" />} />
        <Route path="fixed-navbar" element={<Navbar item="fixed-navbar" />} />
        <Route path="quick-login-dropdown" element={<Navbar item="login" />} />
        
        <Route path="header-image" element={<Headers item="image" />} />
        <Route path="header-background" element={<Headers item="header-background" />} />
        <Route path="header-height" element={<Headers item="height" />} />
        <Route path="webp-format" element={<Headers item="webp" />} />
        <Route path="mobile-size" element={<Headers item="mobile" />} />
        <Route path="parallax-header" element={<Headers item="parallax" />} />
        <Route path="header-title" element={<Headers item="header-title" />} />

        <Route path="panel-head-image" element={<Panels item="image" />} />
        <Route path="panel-head-color" element={<Panels item="color" />} />
        <Route path="panel-head-overlay" element={<Panels item="overlay" />} />
        <Route path="collapsible-panel" element={<Panels item="collapsible" />} />
        <Route path="last-visit-and-current-time" element={<Panels item="last-visit" />} />
        <Route path="sidebars" element={<Panels item="sidebars" />} />
        <Route path="profile-view" element={<Panels item="profile" />} />
        <Route path="code-style" element={<Panels item="code-style" />} />

        <Route path="footer-items" element={<Footers item="items" />} />
        <Route path="social-media-items" element={<Footers item="social" />} />
        <Route path="custom-copyright" element={<Footers item="copyright" />} />

        <Route path="custom-css-codes" element={<Custom item="css-code" />} />
        <Route path="custom-js-codes" element={<Custom item="js-code" />} />
      </Route>
    </Routes>
  );
};

export default MainRouter;
