import React from "react";

const Script = () => {
  return (
    <div>
      <p className="mb-3 text-sm font-semibold text-blue-600">Getting Started</p>
      <h1 className="page-title block text-2xl font-bold text-gray-800 pb-5 border-b">Install phpBB</h1>
      <ol className="relative space-y-2 doc-installation-step mt-5">
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="text-gray-800">
            Log in to cPanel and choose <span className="text-blue-600 mx-0.5">phpBB</span> from <span className="text-blue-600 mx-0.5">Softaculous Apps Installer</span> 
          </p>
          <img className="rounded-lg shadow border mt-3 max-w-full w-fit" src="./assets/images/cpanel-1.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="text-gray-800">
            Click on <span className="text-blue-600 mx-0.5">Install Now</span> button.
          </p>
          <img className="rounded-lg shadow border mt-3 max-w-full w-fit" src="./assets/images/install-phpbb-1.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="text-gray-800">Fill in the required data</p>
          <p className="text-gray-600 text-sm">Type the name of the folder where phpBB will be installed.</p>
          <p className="text-gray-600 text-sm">For example: https://example.com/forums</p>
          <p className="text-gray-600 text-sm">Also, you can leave it blank so the link is: https://example.com</p>
          <p className="text-gray-600 text-sm">Choose a username and password that you will use later to access the phpBB control panel.</p>
          <img className="rounded-lg shadow border mt-3 max-w-full w-fit" src="./assets/images/install-phpbb-2.png" />
        </li>
        <li className="relative ps-12 pb-8 last:pb-0 before:content-[counter(step)] before:absolute before:start-0 before:flex before:items-center before:justify-center before:size-7 before:text-xs before:font-semibold before:text-slate-700 before:rounded-full before:border before:border-slate-200 last:after:hidden after:absolute after:top-[calc(1.75rem_+_.5rem)] after:bottom-0 after:start-[0.875rem] after:w-px after:bg-slate-200" style={{counterIncrement: "step 1"}}>
          <p className="text-gray-800">
            Congratulations, phpBB was installed successfully.
          </p>
          <img className="rounded-lg shadow border mt-3 max-w-full w-fit" src="./assets/images/install-phpbb-3.png" />
        </li>
      </ol>
    </div>
  );
};

export default Script;
